import { PropTypes as AdoptersProps } from "../../components/layouts/adopters/adopters"
import blues from "../../images/adopters/blues.svg"
import coinbase from "../../images/adopters/coinbase.svg"
import connctd from "../../images/adopters/connctd.svg"
import contraste from "../../images/adopters/contraste.svg"
import fandom from "../../images/adopters/fandom.svg"
import hootsuite from "../../images/adopters/hootsuite.svg"
import inmusic from "../../images/adopters/inmusic.svg"
import lunasec from "../../images/adopters/lunasec.svg"
import nortal from "../../images/adopters/nortal.svg"
import padis from "../../images/adopters/padis.svg"
import raspberrypi from "../../images/adopters/raspberrypi.svg"
import segment from "../../images/adopters/segment.svg"
import socgen from "../../images/adopters/socgen.svg"
import sumup from "../../images/adopters/sumup.svg"
import tieriv from "../../images/adopters/tieriv.svg"

export const sharedAdopters: AdoptersProps = {
  id: "shared.adopters",
  title: "Trusted by",
  adopters: [
    {
      title: "Sum Up",
      image: sumup,
      url: "https://sumup.com/",
      width: 444,
      height: 128,
    },
    {
      title: "Segment",
      image: segment,
      url: "https://segment.com/",
      width: 360,
      height: 78,
    },
    {
      title: "Fandom",
      image: fandom,
      url: "https://fandom.com/",
      width: 618,
      height: 155,
    },
    {
      title: `Societe Generale`,
      image: socgen,
      url: "https://www.societegenerale.com/fr",
    },
    {
      title: "Raspberry Pi",
      image: raspberrypi,
      url: "https://www.raspberrypi.org/",
      width: 2310,
      height: 619,
    },
    {
      title: "Coinbase",
      image: coinbase,
      url: "https://coinbase.com/",
      width: 1101,
      height: 197,
    },
    {
      title: "Hootsuite",
      image: hootsuite,
      url: "https://hootsuite.com",
      width: 128,
      height: 28,
    },
    {
      title: `inMusic`,
      image: inmusic,
      url: "https://inmusicbrands.com/",
      width: 883,
      height: 194,
    },
    {
      title: "BluesWireless",
      image: blues,
      url: "https://blues.io/",
      width: 157,
      height: 21,
    },
    {
      title: `Nortal`,
      image: nortal,
      url: "https://nortal.com",
      width: 600,
      height: 212,
    },
    {
      title: "Padis",
      image: padis,
      url: "https://padis.io/",
      width: 392,
      height: 102,
    },
    {
      title: "Contraste Digital",
      image: contraste,
      url: "https://www.contraste.com/en/digital",
      width: 700,
      height: 146,
    },
    {
      title: `TIER IV`,
      image: tieriv,
      url: "https://tier4.jp/en/",
      width: 589,
      height: 147,
    },
    {
      title: "Lunasec",
      image: lunasec,
      url: "https://www.lunasec.io/",
      width: 1250,
      height: 330,
    },
  ],
}
